.red{
    color: rgb(255, 44, 44) !important;
    border-color: rgb(255, 44, 44) !important;
}

.yellow{
    color: rgb(252, 105, 52);
    border-color: rgb(252, 105, 52);
}

.green{
    color: rgb(33, 175, 33);
    border-color: rgb(33, 175, 33);
}

.lineup-list{
    margin: 10px;
}

.timeout-chooser{
    text-align: center;
}