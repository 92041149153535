.card{
    margin: 20px;
    max-width: 300px;
}
.card-avatar{
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    color: white;
}
.card-media{
    width: 300px;
    height: 300px;
}

.person-icon{
    font-size: 300px;
}