.active-stepper.active-stepper{
    border-color: #ff8e53;
    color: #ff8e53;
}
.completed-stepper{
    border-color: #ff8e53;
}

.line-stepper{
    border-width: 2px;
}

.border-gradient{
    border-width: 2px;
    border-color: #bdbdbd;
    border-image: linear-gradient(
        to bottom, 
        #ff8e53, 
        #bdbdbd
      ) 1;
}

.container-alignment{
    margin:auto;
    margin-top: 40px;
}

.margin_right{
    margin-right: 10px;
}

.min_width{
    min-width: 80px;
}
