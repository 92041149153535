.loginform{
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.field{
    margin: 10px;
    min-width: 300px;
}

@media screen and (max-width: 332px) {
    .field{
        min-width: 90%;
    }
}