.card{
    margin: 20px;
    max-width: 300px;
}
.card-avatar{
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    color: white;
}
.card-media{
    width: 300px;
    height: 300px;
}
.detail-avatar{
    font-size: xx-large;
    width: 150px;
    height: 150px;
    margin: auto;
}
.cont{
    margin: auto;
    margin-top: 20px;
}
.details-paper{
    padding: 15px;
}