.match-control-nav{
    padding: 2rem;
    margin: 1rem;
    background-color: #ff8e53;
    display: flex;
    justify-content: space-between;
}

.match-control-nav-button{
    background-color: white;
    margin-right: 1rem;
    width: fit-content;
}

@media screen and (max-width: 992px) {
    .match-control-nav{
        display: block;
        text-align: center;
    }
}