.clickable{
    cursor: pointer;
}

.nonclickable{
    cursor:default;
}

.rightside-item{
    float: right;
}

.leftside-item{
    float: left;
}