.controllable-match-nav-tg{
    padding: 2rem;
    margin: 1rem;
    background-color: #ff8f5388;
    display: block;
}

.match-control-nav-button-tg{
    background-color: white;
    margin-right: 1rem;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .match-control-nav{
        display: block;
        text-align: center;
    }
}