.container{
    margin-top: 40px;
}

.red{
    color: rgb(255, 44, 44);
    border-color: rgb(255, 44, 44);
}

.yellow{
    color: rgb(252, 105, 52);
    border-color: rgb(252, 105, 52);
}

.green{
    color: rgb(33, 175, 33);
    border-color: rgb(33, 175, 33);
}

.similarity-row{
    background-color: lightgray;
}