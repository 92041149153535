.root {
    flex-grow: 1;
}
  
.texttitle {
    flex-grow: 1;
}

.spacing{
    margin-right: 20px;
}

.login-button{
    justify-self: right;
}