.container{
    align-self: center;
}

.cropContainer{
    position: relative;
    width: 800px;
    height: 600px;
    background: #333;
}

.cropButton{
    margin-left: 16px;
}

.controls {
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sliderContainer {
    display: flex;
    flex: 0.4;
    align-items: center;
}

.slider {
    padding: 22px 0px;
    margin-left: 16px;
    width: 150px;
}

.nonclickable{
    cursor: default;
}

.clickable{
    cursor: pointer;
}

.association-details{
    margin-bottom: 40px;
    padding: 10px;
}

.association-icon{
    font-size: 300px;
}

.pagination{
    flex-shrink: 0;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}