.cont{
    margin: auto;
    margin-top: 20px;
}

.paper{
    padding: 15px;
}

.aligncenter{
    text-align: center;
}

.progressbar{
    max-width: 30px;
    margin: auto;
}

.paper{
    width: 72px;
    margin: auto;
    text-align: center;
}

.banner-img{
    width: 50%;
    height: 20vh;
    object-fit: cover;
}

.image-container{
    width: 45%;
}

.lineup-table{
    margin: 10px;
}

.clickable{
    cursor: pointer;
}

.nonclickable{
    cursor:default;
}

.marginaftertimeline{
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.timeline-element-paper{
    padding: 6px 16px;
}

.timeline-element-minute-text{
    padding-top: 8px;
}

.timeline-container{
    margin-bottom: 20px;
}

.scores-grid{
    padding-top: 1rem;
    padding-bottom: 1rem;
}